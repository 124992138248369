import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { HeaderContext } from "@/context/HeaderContext";
import axios from "axios";
import classNames from "classnames";
import { css } from "@emotion/css";
import settings from "@/settings";
import { slugify } from "@/helpers";
import { useQuery } from "react-query";

const calendarIcons = {
  Daily: ["far", "clock"],
  Weekly: ["far", "calendar-clock"],
  Monthly: ["far", "calendar-range"],
  Quarterly: ["far", "stopwatch"],
  Annually: ["far", "calendar"],
  Optional: ["far", "gear"],
};

export const MinistryContext = createContext("");

// Adds a few helpful properties to ministrise and ministryCategories
const addHelperData = function (arr, which) {
  if (!which) return arr;
  if (which === "ministryCategory") {
    return arr.map((mc, i) => {
      return {
        ...mc,
        image: mc.hero_image
          ? `${settings.s3Url}/${mc.hero_image}`
          : settings.useFakeData
          ? `https://source.unsplash.com/random/1280x720?${i}`
          : false,
        ministries: addHelperData(
          mc.ministries.sort((a, b) => a.title.localeCompare(b.title)),
          "ministry"
        ),
      };
    });
  }

  if (which === "ministry") {
    return arr.map((m) => {
      return {
        ...m,
        slug: m.slug ? m.slug : slugify(m.title),
        job_description: m.job_description ? Object.keys(m.job_description).map((key) => m.job_description[key]) : [],
        pages: m.pages
          ? Object.keys(m.pages).map((key) => {
              const page = m.pages[key];
              return {
                ...page,
                slug: page.slug ? page.slug : slugify(page.title),
              };
            })
          : [],
      };
    });
  }

  return arr;
};

export const MinistryProvider = ({ children }) => {
  const { setErrors } = React.useContext(HeaderContext);
  const [currentMinistryCategory, setCurrentMinistryCategory] = useState(false);
  const [currentMinistry, setCurrentMinistry] = useState(false);
  const [currentMinistrySubpage, setCurrentMinistrySubpage] = useState(false);
  const [currentMinistrySubSubpage, setCurrentMinistrySubSubpage] = useState(
    false
  );
  const { breadcrumb, setBreadcrumb, buildBreadcrumb } = useContext(
    HeaderContext
  );
  const {
    ministry,
    ministryCategory,
    ministrySubpage,
    ministrySubSubpage,
  } = useParams();
  const location = useLocation();
  let history = useHistory();

  // Fetch the data from the backend api
  const ministryCategories = useQuery("ministryCategories", async () => {
    setErrors(false);
    const res = await axios(`${settings.apiBase}/ministries-categories`);
    return {
      ...res,
      data: [...res.data.data],
    };
  });

  // Takes the raw data from ministryCategories and adds helper data
  const allMinistryCategoryData = React.useMemo(() => {
    return {
      loading: ministryCategories.isLoading,
      error: ministryCategories.error,
      dataPresent: ministryCategories.isSuccess,
      data:
        ministryCategories.data && "data" in ministryCategories.data
          ? addHelperData(ministryCategories.data.data, "ministryCategory")
          : false,
    };
  }, [ministryCategories]);

  const allMinistries = React.useMemo(() => {
    if (!allMinistryCategoryData.data) return [];
    const ministries = [];
    allMinistryCategoryData.data.map((c) => {
      return c.ministries.map((m) => ministries.push(m));
    });
    return addHelperData(ministries, "ministry");
  }, [allMinistryCategoryData]);

  // Helper that ensures all data is loaded properly
  const goodToGo = React.useMemo(() => {
    return (
      ministryCategories.isSuccess &&
      ministryCategories.data &&
      ministryCategories.data.data
    );
  }, [ministryCategories]);

  // Parses ministry calendar data into a more usable format
  const calendarData = React.useMemo(() => {
    if (!allMinistryCategoryData?.dataPresent) return false;
    const data = {
      categories: [],
    };

    // Generate list of unique calendar event categories (daily, weekly, etc)
    allMinistryCategoryData.data.forEach((d) => {
      d.ministries.forEach((m) => {
        Object.keys(m.calendars).forEach((cal) => {
          const c = m.calendars[cal];
          const existingCategory = data.categories.findIndex(
            (cat) => cat.id === c.calendar_category.id
          );

          if (existingCategory < 0) {
            data.categories.push({
              ...c.calendar_category,
              icon: calendarIcons[c.calendar_category.title],
            });
          }
        });
      });
    });

    return {
      ...data,
      categories: data.categories.sort((a, b) =>
        a.sort_order > b.sort_order ? 1 : -1
      ),
    };
  }, [allMinistryCategoryData]);

  // Prepares the calendar data for a given ministry
  // so it can be displayed in the calendar UI box.
  const prepareCalendars = function (calendars) {
    if (!calendars) return false;

    const categories = [];
    Object.keys(calendars).forEach((calendar) => {
      // TODO: Create helper function to convert objects into arrays
      const c = calendars[calendar];
      const existingCat = categories.findIndex(
        (cat) => c.calendar_category.id === cat.id
      );

      if (existingCat < 0) {
        categories.push({
          ...c.calendar_category,
          icon: calendarIcons[c.calendar_category.title],
          items: [{ ...c }],
        });
      } else {
        categories[existingCat].items.push(c);
      }
    });
    return categories;
  };

  // Set breadcrumbs
  useEffect(() => {
    if (
      !currentMinistryCategory &&
      !currentMinistry &&
      !currentMinistrySubpage &&
      !currentMinistrySubSubpage &&
      !breadcrumb
    )
      return;
    setBreadcrumb(
      buildBreadcrumb(
        [
          currentMinistryCategory,
          currentMinistry,
          currentMinistrySubpage,
          currentMinistrySubSubpage,
        ]
          .filter((el) => el)
          .map((el) => {
            return {
              title: el.title,
              link: el.link,
            };
          })
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentMinistry,
    currentMinistryCategory,
    currentMinistrySubpage,
    currentMinistrySubSubpage,
  ]);

  // First we try to set the ministryCategory based on slug params
  useEffect(() => {
    if (
      !goodToGo ||
      (currentMinistryCategory &&
        currentMinistryCategory.slug === ministryCategory) ||
      !ministryCategory
    )
      return;

    // if (!ministryCategory) return setCurrentMinistryCategory(false);

    let c = ministryCategories.data.data.filter(
      (m) => m.slug === ministryCategory
    );

    if (c.length === 0) return history.replace(settings.notFoundPath);

    c = addHelperData(
      c.map((m) => {
        return {
          ...m,
          ministries: addHelperData(m.ministries, "ministry"),
        };
      }),
      "ministryCategory"
    )[0];

    setCurrentMinistryCategory(c);
  }, [
    goodToGo,
    ministryCategory,
    location,
    currentMinistryCategory,
    history,
    ministryCategories,
  ]);

  // Then we try to set the ministry based on slug params
  useEffect(() => {
    if (
      !goodToGo ||
      !currentMinistryCategory ||
      (currentMinistry && currentMinistry.slug === ministry)
    )
      return;

    if (!ministry) return setCurrentMinistry(false);

    let c = currentMinistryCategory.ministries.filter(
      (m) => m.slug === ministry
    );

    if (c.length === 0) return history.replace(settings.notFoundPath);

    c = addHelperData(c, "ministry")[0];
    c.calendars = prepareCalendars(c.calendars);

    setCurrentMinistry(c);
  }, [goodToGo, currentMinistryCategory, ministry, currentMinistry, history]);

  // Then we try to set the ministry subpage based on slug params
  useEffect(() => {
    if (
      !goodToGo ||
      !currentMinistry ||
      (currentMinistrySubpage &&
        currentMinistrySubpage.slug === ministrySubpage)
    )
      return;
    if (!ministrySubpage) return setCurrentMinistrySubpage(false);

    let c =
      currentMinistry.pages.find((m) => m.slug === ministrySubpage) || false;

    if (!c) return history.replace(settings.notFoundPath);

    setCurrentMinistrySubpage(c);
  }, [
    goodToGo,
    currentMinistry,
    ministrySubpage,
    currentMinistrySubpage,
    history,
  ]);

  // Then we try to set the ministry sub-subpage based on slug params
  useEffect(() => {
    if (
      !goodToGo ||
      !currentMinistry ||
      !currentMinistrySubpage ||
      (currentMinistrySubSubpage &&
        currentMinistrySubSubpage.slug === ministrySubSubpage)
    )
      return;
    if (!ministrySubSubpage) return setCurrentMinistrySubSubpage(false);

    let c =
      currentMinistrySubpage.sub_pages.find(
        (m) => m.slug === ministrySubSubpage
      ) || false;

    if (!c) return history.replace(settings.notFoundPath);
    setCurrentMinistrySubSubpage(c);
  }, [
    goodToGo,
    currentMinistrySubpage,
    ministrySubSubpage,
    currentMinistry,
    currentMinistrySubSubpage,
    history,
  ]);

  if (ministryCategories?.error) {
    setErrors(ministryCategories?.error);
  }

  const findMinistry = (id) => {
    return allMinistries.find((m) => m.id === id);
  };

  const findMinistryBySlug = (slug) => {
    return allMinistries.find((m) => m.slug === slug);
  };

  const findPage = (id, ministryId) => {
    const ministry = findMinistry(ministryId);
    return ministry.pages.find((p) => p.id === id);
  };

  const findContent = (id, pageId, ministryId) => {
    const page = findPage(pageId, ministryId);
    return Object.values(page.content).find((p) => p.id === id);
  };

  // Provide the context and set the ministry color CSS vars
  return (
    <MinistryContext.Provider
      value={{
        currentMinistryCategory,
        currentMinistry,
        currentMinistrySubpage,
        currentMinistrySubSubpage,
        ministryCategories,
        allDataLoaded: ministryCategories
          ? ministryCategories?.isSuccess
          : false,
        calendarIcons,
        calendarData,
        allMinistryCategoryData,
        allMinistries,
        findMinistry,
        findMinistryBySlug,
        findPage,
        findContent,
      }}
    >
      <div
        className={classNames(css`
          --ministryColor: ${currentMinistryCategory &&
          "color_code" in currentMinistryCategory
            ? currentMinistryCategory.color_code
            : "var(--color-blue-500)"};

          .hover\\:text-ministry:hover,
          .text-ministry {
            color: var(--ministryColor);
          }

          .hover\\:bg-ministry:hover,
          .bg-ministry {
            background-color: var(--ministryColor);
          }

          .border-ministry {
            border-color: var(--ministryColor);
          }
        `)}
      >
        {children}
      </div>
    </MinistryContext.Provider>
  );
};
