import React from "react";
import { css } from "@emotion/css";
import Algolia from "@/lib/Algolia";
import {
  Hits,
  SearchBox,
  Configure,
  connectStateResults,
} from "react-instantsearch-dom";
import SearchResult from "@/components/Search/SearchResult";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useComponentVisible from "@/hooks/useComponentVisible";

function SearchOverlay({ isOpen, setIsOpen }) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const searchWatcher = (e) => {
    if (e.target && "value" in e.target && e.target.value.length >= 2) {
      setIsComponentVisible(true);
    } else {
      setIsComponentVisible(false);
    }
  };

  React.useEffect(() => {
    const body = document.querySelector("body");
    return isOpen
      ? body.classList.add("overflow-hidden")
      : body.classList.remove("overflow-hidden");
  }, [isOpen]);

  const Results = connectStateResults(({ searchResults }) =>
    searchResults && "nbHits" in searchResults && searchResults.nbHits > 0 ? (
      <Hits hitComponent={SearchResult} />
    ) : (
      <div className="px-6 py-2">Nothing found...</div>
    )
  );

  return (
    <div
      ref={ref}
      className={classNames(
        "fixed inset-0 z-30 bg-white p-6 ease-in duration-200 transform transition opacity-0",
        {
          "opacity-0 scale-90 pointer-events-none": !isOpen,
          "opacity-100 scale-100": isOpen,
        },
        css`
          .ais-SearchBox-input {
            width: 100%;
            padding: var(--p-2) var(--p-6) var(--p-2) var(--p-12);
          }

          .ais-SearchBox-submit {
            display: none;
          }
        `
      )}
    >
      <Algolia>
        <Configure hitsPerPage={10} />
        <div className="flex items-center w-full gap-3 text-sm">
          <div className="relative flex-1 text-blue-500">
            <SearchBox
              translations={{
                placeholder: "Search",
              }}
              autoFocus={false}
              onKeyUp={searchWatcher}
              reset={false}
            />
            <FontAwesomeIcon
              className="absolute top-1/2 left-0 transform translate-x-4 -translate-y-1/2 text-lg"
              icon={["far", "search"]}
            />
          </div>
          <button
            onClick={() => setIsOpen(false)}
            className="font-medium text-gray-600"
          >
            Cancel
          </button>
        </div>
        {isComponentVisible && (
          <div onClick={() => setIsOpen(false)}>
            <Results />
          </div>
        )}
      </Algolia>
    </div>
  );
}

export default React.memo(SearchOverlay);
